@import '../../styles/main';

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    transition-duration: 0.075s;
    transition-property: opacity, background-color;
    transition-timing-function: linear;

    z-index: 999; //eruda=1000

    background-color: white;

    @include themeDark {
        background-color: black;
    }

    &:not(.loaded) + * {
        display: none;
    }

    &.loaded {
        background-color: transparent;
        opacity: 0;
        pointer-events: none;
    }
}
