:root {
    --readex-pro: 'Readex Pro', Arial, sans-serif;
    --jetbrains-mono: 'JetBrains Mono', monospace;
}

html,
body,
#root {
    height: 100%;
}

.font-monospace {
    font-family: var(--jetbrains-mono) !important;
}

.flex-1 {
    flex: 1 !important;
}

.code:not(.language-markdown .code) {
    font-family: var(--jetbrains-mono);
    padding: 2px 4px;
    margin: 0 2px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.cursor-help {
    cursor: help;
}
