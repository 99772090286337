@import '../../styles/main';

.container {
    min-height: 100% !important;

    .mainLayout {
        display: flex;
        flex-direction: row;
        flex: 1;

        .contentContainer {
            height: auto;

            width: calc(100vw - 17px);
            max-width: 100%;

            @include mediaLG {
                width: calc(100vw - 217px);
            }
        }
    }
}
