@use 'sass:selector';

$mediaXS: 0;
$mediaSM: 576px;
$mediaMD: 768px;
$mediaLG: 992px;
$mediaXL: 1200px;
$mediaXXL: 1600px;

@mixin mediaXS {
    @media (min-width: $mediaXS) {
        @content;
    }
}

@mixin mediaSM {
    @media (min-width: $mediaSM) {
        @content;
    }
}

@mixin mediaMD {
    @media (min-width: $mediaMD) {
        @content;
    }
}

@mixin mediaLG {
    @media (min-width: $mediaLG) {
        @content;
    }
}

@mixin mediaXL {
    @media (min-width: $mediaXL) {
        @content;
    }
}

@mixin mediaXXL {
    @media (min-width: $mediaXXL) {
        @content;
    }
}

@mixin themeDark {
    @at-root body[data-theme='dark'] & {
        @content;
    }
}

@mixin themeLight {
    @at-root body:not([data-theme='dark']) & {
        @content;
    }
}

//---/--/---/--/---/--/---/--/---/--/---/--/---/--/---/--/---//

$borderColorLight: #d9d9d9;
$borderColorDark: #434343;

@mixin borderColor {
    border-color: $borderColorLight;

    @include themeDark {
        border-color: $borderColorDark;
    }
}

$monacoBackgroundLight: white;
$monacoBackgroundDark: rgb(30, 30, 30);

@mixin monacoBackground {
    background-color: $monacoBackgroundLight;

    @include themeDark {
        background-color: $monacoBackgroundDark;
    }
}

$contentHeight: calc(100vh - 64px - 76px);

$primaryColorLight: #1890ff;
$primaryColorDark: rgb(23, 125, 220);

@mixin primaryColor {
    color: $primaryColorLight;

    @include themeDark {
        color: $primaryColorDark;
    }
}

.antd-text-primary {
    @include primaryColor;
}

.ant-tabs-first-tab-indent {
    .ant-tabs-tab:first-of-type {
        margin-left: 16px;
    }
}

.ant-tabs-no-w100-but-max {
    .ant-tabs-tabpane {
        width: auto !important;
        max-width: 100%;
    }
}
