//html,
//body {
//    background: #212121 !important;
//    color: white !important;
//}

.focus-visible-unstyled {
    &:focus-visible {
        outline: none;
    }
}

.app-monaco-editor {
    .monaco-editor {
        width: 100% !important;

        .overflow-guard {
            width: 100% !important;
        }
    }
}

.view-iframe {
    flex: 1;
    border: none;
    max-width: 100%;
    overflow: auto;
}

.app-alert {
    align-items: flex-start;

    .ant-alert-close-icon {
        margin-top: 6px;
    }
}
