.container {
    position: relative;
}

.autoComplete {
    //margin-left: 80px;
    width: 230px;
    max-width: 230px;
}

.keyHint {
    position: absolute;
    right: 40px;
    top: -2px;
    pointer-events: none;
}
