@import '../../../../styles/main';

.sideMenuDrawer {
    :global {
        .ant-drawer-header {
            &.ant-drawer-header-close-only {
                padding-right: 4px;
            }

            .ant-drawer-header-title {
                justify-content: flex-end;
            }
        }
    }
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    @include mediaLG {
        padding: 0 25px;
    }

    //background: #1f1f1f;

    .logo {
        .logoText {
            color: white;
            font-size: 18px;
            font-family: var(--readex-pro);
        }

        padding: 0 16px;

        @include mediaLG {
            padding: unset;
        }
    }

    .menuButton {
        color: white;

        padding: 4px 25px;

        @include mediaSM {
            padding: 4px 20px 4px 13px;
        }
    }

    .themeSwitch {
        padding-top: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .themeSwitchIcon {
            height: 18px;
        }

        @include themeLight {
            background: rgba(150, 150, 150, 0.4) !important;
        }

        @include themeDark {
            background: rgba(80, 80, 80, 0.8) !important;

            :global(.ant-switch-handle:before) {
                background-color: #f0f0f0;
            }
        }
    }

    .search {
        margin-top: -5px;
    }
}
