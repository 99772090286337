@import '../../../../styles/main';

.container {
    display: none !important;

    @include mediaLG {
        display: block !important;
    }

    @include themeDark {
        //background: rgb(20, 20, 20);
    }

    .containerScrollWrapper {
        position: sticky;
        top: 0;
        height: 100vh;

        .containerScroll {
            //max-height: calc(100vh - 64px);
            overflow-y: auto;
            overflow-x: hidden;
            will-change: top, max-height;
            //position: sticky;
            //top: 0;
        }
    }

    :global(.ant-layout-sider-children) {
        padding-top: 0;
        margin-top: 0;
    }
}
